
import { GlobalStore } from "./GlobalStore"
import { createContext, useContext } from "react";

export const GlobalStoreContext = createContext<GlobalStore | null>(null)

export const useStore = () => {
  const context = useContext(GlobalStoreContext);
  if (context === null) {
    throw new Error(
      "You have forgotten to wrap your root component with GlobalStoreContext"
    );
  }
  return context;
};