import { makeAutoObservable, runInAction } from "mobx";

export type SmsMessage = { phone: string; body: string; };
export class SmsStore {
    
    public Messages: SmsMessage[] = [];
    constructor(private readonly token: string) {
        makeAutoObservable(this);
    }

    public async Load() {
        const response = await fetch("/api/v1/messages", {
            headers: {
                Authorization: `Bearer ${this.token}`,
            }
        });
        const data = await response.json() as SmsMessage[];
        runInAction(() => { this.Messages =  data });
    }

    public async reply(phone: string, body: string) {
        await fetch("/api/v1/messages", {
            method: "POST",
            body: JSON.stringify({ phone: phone, body: body }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          });
    }
}