import styled, { css } from "styled-components";

export const Workspace = styled.div`
  --gutter-size: 1px;

  display: grid;
  height: 100vh;

  grid-template-columns: 200px 1px minmax(0, 1fr);
  grid-template-rows: 10vh minmax(0, 1fr);

  grid-template-areas:
    "header header header"
    "navigate gutter1 editor";

  #header {
    grid-area: header;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }

  #editor {
    grid-area: editor;
  }

  #runner {
    grid-area: runner;
  }
`;

export const Header = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
`;

export const Editor = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Navigate = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  display: flex;
`;
