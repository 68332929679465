import { makeAutoObservable, runInAction } from "mobx";
import * as SIP from "sip.js";
import { v4 as uuidv4 } from "uuid";


// @ts-ignore
const AudioContext = window.AudioContext ?? window.webkitAudioContext;

export default class SIPClient {
  private sip: SIP.Web.SimpleUser;
  private audio = new Audio();
  private isInitialized = false;

  private audioCtx = new AudioContext();
  private mediaStreamSource?: MediaStreamAudioSourceNode;
  public endpoint: string;
  public server: string;
  public isConnected: boolean;
  constructor(public domain: string) {
    this.endpoint = `sip:reg-${uuidv4()}@${domain}`;
    if (domain.startsWith("localhost")) {
      this.server = `ws://${domain}/sip/connect`;
    } else {
      this.server = `wss://${domain}/sip/connect`;
    }
    makeAutoObservable(this);
  }


  async dispose(): Promise<void> {
    await this.sip?.unregister();
  }

  async hangup(): Promise<void> {
    await this.sip?.hangup().catch((e) => {});
  }
  async initialize(): Promise<string> {
    if (this.isInitialized) {
      return this.endpoint;
    }

    const options: SIP.Web.SimpleUserOptions = {
      aor: this.endpoint,
      media: {
        constraints: {
          audio: { echoCancellation: true },
        } as any, // badly typed library,
      },
      delegate: {
        onServerDisconnect: (error) => {
          console.error(error);
          this.isInitialized = false;
        },
        onCallReceived: async () => {
          try {
            this.mediaStreamSource?.disconnect();

            console.info("call received");
            await this.sip.answer();

            this.audio.srcObject = this.sip.remoteMediaStream ?? null;
            await this.audio.play();

            if (this.sip.localMediaStream) {
              this.mediaStreamSource = this.audioCtx.createMediaStreamSource(this.sip.localMediaStream);
              await this.audioCtx.resume();
            }
            runInAction(()=>{
              this.isConnected = true;
            });
          } catch (error) {
            console.error(error);
          }
        },
        onCallHangup: () => {
          runInAction(()=>{
            this.isConnected = false;
          });
          console.info("call hangup");
          this.audio.srcObject = null;
        },
      },
    };

    this.sip = new SIP.Web.SimpleUser(this.server, options);    

    try {
      console.info("connecting...");
      await this.sip.connect();

      console.info("registering...");
      await this.sip.register();

      this.isInitialized = true;
      return this.endpoint;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
