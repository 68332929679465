import { observer } from "mobx-react-lite";
import { useStore } from "../store/GlobalStoreContext";
import { FC, useCallback, useEffect, useState } from "react";
import React from "react";
import { Button, Card, Checkbox, Form, Input, Loader } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { IGlobalConfig } from "../../../../src/types";

export const GlobalConfigs: FC<{}> = observer(() => {
  const { configs } = useStore();
  const navigate = useNavigate();
  const [globalConfig, setGlobalConfig] = useState<IGlobalConfig | undefined>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    configs
      .GetGlobalConfigAsync()
      .then((e) => setGlobalConfig(e))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, [configs]);

  const switchScheduler = useCallback(
    (enabled) => {
      setLoading(true);
      configs
        .SetGlobalConfigAsync({
          ...globalConfig,
          ...{ schedulerEnabled: enabled },
        })
        .then((v) => setGlobalConfig(v))
        .finally(() => setLoading(false));
    },
    [configs, globalConfig]
  );
  return (
    <>
      <h1>Global configs</h1>
      <Loader active={loading}></Loader>
      {globalConfig && (
        <Form>
          <Form.Field>
            <Checkbox
              checked={globalConfig.schedulerEnabled}
              onChange={(e, d) => switchScheduler(d.checked)}
              toggle
              label={"Scheduler enabled"}
            >
              
            </Checkbox>
          </Form.Field>
        </Form>
      )}
    </>
  );
});
