import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { FC } from "react";
import { useStore } from "../store/GlobalStoreContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  Label,
  Loader,
  Message,
  Modal,
  Radio,
  Tab,
  TextArea,
} from "semantic-ui-react";
import { IConfig } from "../../../../src/types";
import { SmsMessage } from "../store/SmsStore";

const languages = ["en", "de"];

const transformPhrases = (value: {
  [key: string]: string[];
}): { [key: string]: string } => {
  const current = Object.fromEntries(
    Object.entries(value).map(([k, v]) => [k, v.join("\n")])
  );

  current.filler = current.filler ?? "Okay...\nUhm...\nHmm...\nUmm...";
  return current;
};

const InvTransfromPhrases = (value: {
  [key: string]: string;
}): { [key: string]: string[] } => {
  return Object.fromEntries(
    Object.entries(value).map(([k, v]) => [
      k,
      v
        .split("\n")
        .map((v) => v.trim())
        .filter((v) => v !== ""),
    ])
  );
};

const ConfigEditorInternal: FC<{
  configId: string;
  config: IConfig;
}> = observer(({ configId, config }) => {
  const { configs, sip, token, sms } = useStore();
  const [callError, setCallError] = useState<string | undefined>();
  const [language, setLanguage] = useState(config.language);
  const [description, setDescription] = useState(config.description);
  const [location, setLocation] = useState(config.location);
  const [script, setScript] = useState(config.script);
  const [timezone, setTimezone] = useState(config.timezone);
  const [eventTypeId, setEventTypeId] = useState(config.eventTypeId);
  const [testLead, setTestLead] = useState(config.testLead);
  const [sms1, setSMS1] = useState(config.firstSend);
  const [sms2, setSMS2] = useState(config.secondSend);
  const [sms3, setSMS3] = useState(config.thirdSend);
  const [prompt, setPrompt] = useState(config.prompt ?? configs.Default.prompt);
  const [isCalling, setIsCalling] = useState(false);
  const [smsReply, setSMSReply] = useState("");
  const [saveAsOpen, setSaveAsOpen] = useState(false);
  const [speaker, setSpeaker] = useState(
    config.speaker ?? "bJ2EakS3LaqGfBtFxaFu"
  );
  const navigate = useNavigate();
  const [saveAsName, setSaveAsName] = useState("");
  const [sourcePhone, setSourcePhone] = useState(config.sourcePhone ?? "");
  const [lastMockData, setLastMockData] = useState<{
    id: string;
    results: { output: any; transription: any } | undefined;
  }>();
  const [toCallNumber, setToCallNumber] = useState("");
  const [staticPhrases, setStaticPhrases] = useState(
    transformPhrases(
      config.staticPhrases ?? configs.Default.staticPhrases ?? {}
    )
  );

  const [schedulerEnabled, setSchedulerEnabled] = useState(config.schedulerEnabled);

  useEffect(() => {
    const id = setInterval(() => {
      sms.Load();
      fetch("/api/v1/mock", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((x) =>
        x
          .json()
          .catch(() => {})
          .then((y) => setLastMockData(y))
      );
    }, 5000);

    return () => clearInterval(id);
  }, []);

  const GeneralEditor = () => {
    return (
      <Form>
        <Form.Field>
          <Label>Source phone number</Label>
          <Input
            placeholder="+4961314932205"
            value={sourcePhone ?? ""}
            onChange={(e, d) => setSourcePhone(d.value)}
          ></Input>
        </Form.Field>
        <Form.Field>
          <Label>Language</Label>
          <Dropdown
            value={language}
            options={languages.map((x) => ({ key: x, text: x, value: x }))}
            onChange={(e, d) => setLanguage(d.value?.toString() ?? "en")}
          ></Dropdown>
        </Form.Field>
        <Form.Field>
          <Label>Speaker</Label>
          <Input
            value={speaker ?? ""}
            onChange={(e, d) => setSpeaker(d.value)}
          ></Input>
        </Form.Field>
        <Form.Field>
          <Label>Description</Label>
          <Input
            value={description}
            onChange={(e, d) => setDescription(d.value)}
          ></Input>
        </Form.Field>
        <Form.Field>
          <Label>Location</Label>
          <Input
            value={location}
            onChange={(e, d) => setLocation(d.value)}
          ></Input>
        </Form.Field>

        <Form.Field>
          <Label>Timezone</Label>
          <Input
            value={timezone}
            onChange={(e, d) => setTimezone(d.value)}
          ></Input>
        </Form.Field>

        <Form.Field>
          <Label>Cal.com event id</Label>
          <Input
            value={eventTypeId}
            onChange={(e, d) => setEventTypeId(parseInt(d.value))}
            type="number"
          ></Input>
        </Form.Field>

        <Form.Field>
          <Label>Test lead in close.io</Label>
          <Input
            value={testLead}
            onChange={(e, d) => setTestLead(d.value)}
          ></Input>
        </Form.Field>
      </Form>
    );
  };

  const ScriptEditor = () => {
    return (
      <Form>
        <Form.Field>
          <Label>Script</Label>
          <TextArea
            rows={20}
            placeholder="Your script here"
            value={script}
            onChange={(e, d) => setScript(d.value?.toString() ?? "")}
          />
        </Form.Field>
      </Form>
    );
  };

  const PromptEditor = () => {
    return (
      <Form>
        <Form.Field>
          <Label>Prompt</Label>
          <TextArea
            rows={40}
            placeholder="Your prompt here"
            value={prompt}
            onChange={(e, d) => setPrompt(d.value?.toString() ?? "")}
          />
        </Form.Field>
      </Form>
    );
  };

  const SMSEditor = () => {
    return (
      <Form>
        <Form.Field>
          <Label>First SMS, {sms1.length} characters</Label>
          <TextArea
            rows={6}
            placeholder="Your SMS here"
            value={sms1}
            onChange={(e, d) => setSMS1(d.value?.toString() ?? "")}
          />
        </Form.Field>
        <Form.Field>
          <Label>Second SMS (+1h), {sms2.length} characters</Label>
          <TextArea
            rows={6}
            placeholder="Your SMS here"
            value={sms2}
            onChange={(e, d) => setSMS2(d.value?.toString() ?? "")}
          />
        </Form.Field>
        <Form.Field>
          <Label>Thirds SMS (+24h), {sms3.length} characters</Label>
          <TextArea
            rows={6}
            placeholder="Your SMS here"
            value={sms3}
            onChange={(e, d) => setSMS3(d.value?.toString() ?? "")}
          />
        </Form.Field>
      </Form>
    );
  };

  const StaticPhrases = () => {
    return (
      <Form>
        {Object.entries(staticPhrases).map(([k, v]) => {
          return (
            <Form.Field>
              <Label>{k}</Label>
              <TextArea
                value={v}
                onChange={(e, d) => {
                  setStaticPhrases({
                    ...staticPhrases,
                    ...{ [k]: d.value?.toString() ?? "" },
                  });
                }}
              ></TextArea>
            </Form.Field>
          );
        })}
      </Form>
    );
  };
  const panes = [
    {
      menuItem: "General",
      render: () => <Tab.Pane>{GeneralEditor()}</Tab.Pane>,
    },
    { menuItem: "Prompt", render: () => <Tab.Pane>{PromptEditor()}</Tab.Pane> },
    { menuItem: "Script", render: () => <Tab.Pane>{ScriptEditor()}</Tab.Pane> },
    { menuItem: "SMS", render: () => <Tab.Pane>{SMSEditor()}</Tab.Pane> },
    {
      menuItem: "Static phrases",
      render: () => <Tab.Pane>{StaticPhrases()}</Tab.Pane>,
    },
  ];
  const getActualConfig = useCallback((): IConfig => {
    return {
      name: configId,
      language: language,
      description: description,
      eventTypeId: eventTypeId,
      location: location,
      script: script,
      timezone: timezone,
      firstSend: sms1,
      secondSend: sms2,
      thirdSend: sms3,
      testLead: testLead,
      createdTime: Date.now().valueOf(),
      prompt: prompt,
      staticPhrases: InvTransfromPhrases(staticPhrases),
      speaker: speaker === "" ? undefined : speaker,
      sourcePhone: sourcePhone === "" ? undefined : sourcePhone,
      schedulerEnabled: schedulerEnabled
    };
  }, [
    configId,
    language,
    description,
    eventTypeId,
    location,
    script,
    timezone,
    sms1,
    sms2,
    sms3,
    testLead,
    prompt,
    staticPhrases,
    speaker,
    sourcePhone,
    schedulerEnabled
  ]);

  const makeCall = useCallback(
    (config: IConfig, endpoint: string | undefined) => {
      setCallError(undefined);
      const callAsync = async () => {
        await sms.Load();
        const ep = endpoint ?? (await sip.initialize());
        await fetch("/api/v1/call", {
          method: "POST",
          body: JSON.stringify({ config: config, endpoint: ep }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setIsCalling(true);
        new Promise((resolve) =>
          setTimeout(() => resolve(true), 5000)
        ).then(() => setIsCalling(false));
      };
      callAsync().catch((e) => setCallError(JSON.stringify(e)));
    },
    [sip, sms]
  );

  return (
    <>
      <h1>{configId}</h1>
      <Checkbox toggle checked={schedulerEnabled} onChange={(e, d) => setSchedulerEnabled(d.checked ?? false)} label={"Scheduler enabled"}></Checkbox>
      <Tab panes={panes}></Tab>

      <Button onClick={() => configs.SaveAsync(getActualConfig())}>Save</Button>

      <Modal
        trigger={<Button onClick={() => setSaveAsOpen(true)}>Save as</Button>}
        onClose={() => setSaveAsOpen(false)}
        open={saveAsOpen}
      >
        <Modal.Content>
          <Form>
            <Form.Field>
              <Label>Name</Label>
              <Input
                onChange={(e, d) => setSaveAsName(d.value)}
                value={saveAsName}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setSaveAsOpen(false)} negative>
            Cancel
          </Button>
          <Button
            disabled={saveAsName === "" || configs.Has(saveAsName)}
            onClick={() => {
              configs
                .SaveAsync({
                  ...getActualConfig(),
                  ...{ name: saveAsName },
                })
                .then(() => navigate(`/configs/${saveAsName}`));
            }}
            positive
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>

      {!sip.isConnected && (
        <Button
          onClick={() => makeCall(getActualConfig(), undefined)}
          positive
          disabled={isCalling}
        >
          Call to browser
        </Button>
      )}
      <Form.Field>
        <Input
          placeholder="+49...."
          value={toCallNumber}
          onChange={(e, d) => setToCallNumber(d.value)}
        />

        <Button
          positive
          attached='left'
          disabled={isCalling}
          onClick={() => makeCall(getActualConfig(), toCallNumber)}
        >
          {isCalling ? "Ringing" : "Call to phone number"}
        </Button>
      </Form.Field>
      {sip.isConnected && (
        <Button onClick={() => sip.hangup()} negative>
          Hangup
        </Button>
      )}
      {callError && <Message negative>{callError}</Message>}

      {sms.Messages.length > 0 && (
        <Message>
          Received SMS to `{sms.Messages.at(-1)?.phone}`
          <br />
          {sms.Messages.at(-1)?.body}
          <Input
            placeholder="Your reply"
            onChange={(e, d) => setSMSReply(d.value)}
            value={smsReply}
          />
          <Button
            onClick={() =>
              sms.reply(sms.Messages.at(-1)?.phone ?? "", smsReply)
            }
          >
            Reply
          </Button>
        </Message>
      )}
      <div>
        <Label>Latest results will be here after call</Label>
        {lastMockData && (
          <>
            <a
              href={`https://playground.dasha.ai/inspector/${lastMockData.id}`}
              target="_blank"
            >
              Inspect
            </a>
            <TextArea
              rows={20}
              style={{ width: "100%" }}
              value={JSON.stringify(lastMockData.results, null, 4)}
            ></TextArea>
          </>
        )}
      </div>
    </>
  );
});

export const ConfigEditor: FC<{}> = observer(() => {
  const { configs } = useStore();
  const { configId } = useParams();
  const [config, setConfig] = useState<IConfig | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    configs
      .Load()
      .then(() => setConfig(configs.Get(configId!)))
      .finally(() => setLoading(false));
  }, [configId]);

  if (loading) {
    return <Loader>Loading configs</Loader>;
  }
  if (
    config !== undefined &&
    config !== null &&
    configId !== null &&
    configId !== undefined
  ) {
    return (
      <ConfigEditorInternal
        config={config!}
        configId={configId}
      ></ConfigEditorInternal>
    );
  }

  return <h1>Config not found</h1>;
});
