import { makeAutoObservable, runInAction } from "mobx";
import SIPClient from "../sip/SIPClient";
import oidc from "oidc-client";
import { ConfigStore } from "./ConfigStore";
import { SmsStore } from "./SmsStore";

export class GlobalStore {
  private readonly authServer = "https://auth.dasha.ai";
  private readonly sipServer = "sip.us.dasha.ai";
  public token: string | undefined;
  public userName: string | undefined;
  public isLoading: boolean = true;
  public readonly sip: SIPClient;
  public configs: ConfigStore;
  public sms: SmsStore;

  constructor() {
    this.sip = new SIPClient(this.sipServer);
    this.configs = new ConfigStore(undefined);
    this.sms = new SmsStore("");
    new oidc.UserManager({})
      .signinRedirectCallback(window.location.href)
      .then((user) => {
        console.log(user);
        runInAction(() => {
          this.userName = user.profile.email ?? "user";
          this.token = user.access_token;
          this.OnLogin();
        });

        window.history.pushState(null, "", window.location.pathname);
      })
      .catch(() => this.localLogin())
      .finally(() =>
        runInAction(() => {
          this.isLoading = false;
        })
      );

    makeAutoObservable(this);
  }

  private OnLogin() {
    if (this.token === undefined || this.userName === undefined) {
      return;
    }
    localStorage.setItem("apikey", this.token);
    localStorage.setItem("username", this.userName);
    this.configs = new ConfigStore(this.token);
    this.sms = new SmsStore(this.token);
  }

  public isAuth() {
    return this.token !== undefined;
  }

  private localLogin() {
    this.token = localStorage.getItem("apikey") ?? undefined;
    this.userName = localStorage.getItem("username") ?? undefined;
    this.OnLogin();
  }

  public logout() {
    localStorage.removeItem("apikey");
    localStorage.removeItem("username");
    this.token = undefined;
    this.userName = undefined;
  }

  public login() {
    const manager = new oidc.UserManager({
      authority: this.authServer,
      client_id: "platform_client",
      redirect_uri: window.location.href,
      popup_redirect_uri: window.location.href,
      response_type: "id_token token",
      scope: "openid email platform_api user_properties",
      prompt: "login",
    });

    void manager.signinRedirect();
  }
}
